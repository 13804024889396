import React, {
  Fragment,
  useEffect,
} from 'react'
import { 
  Layout,
  Frame
} from "@shopify/polaris"
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { withFirebase } from '../../../providers/firebase'
import Chart from './chart.js'
const Index = (props) => {
  // const { rules = [] } = props
  // TODO break into separate rule component to handle multiple (instead of hardcoding one)
  // const rule = rules[0]
  const shopDoc = doc(props.firebase.firestore, 'shops', props.shop);
  const userDoc = doc(props.firebase.firestore, 'shops', props.shop, 'users', props.user_id?props.user_id:"");
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(shopDoc);
  const [userData, userDataLoading, userDataError] = useDocumentData(userDoc);
  useEffect(() => {
        if(userData && userData.associated_user && window && window.$crisp){
          try{
            window.$crisp.push(["set", "user:email", [userData.associated_user.email]]);
            window.$crisp.push(["set", "user:nickname", [`${userData.associated_user.first_name} ${userData.associated_user.last_name}`]]);
            window.$crisp.push(["set", "session:data", [[
              ["account_owner", userData.associated_user.account_owner]
            ]]]);
          }catch(e){
          console.log(e)
          }
        }
  }, [userData]); 
  useEffect(() => {
    if(shopData && window && window.$crisp){
      try{
        window.$crisp.push(["set", "session:data", [[
          ["shopify_plan", shopData?.shopData?.plan_display_name],
          ["install_date", new Date(shopData.createdAt.seconds * 1000).toLocaleString()],
        ]]]);
      }catch(e){
        console.log(e)
      }
    }
  }, [shopData]); 
  const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
  const currency = shopData && shopData.shopData && shopData.shopData.currency || "USD";
  const plan_name = shopData && shopData.shopData && shopData.shopData.plan_name;
  
  return props.rawAnalytics &&
        <Chart {...props} locale={locale} currency={currency}/>
        
}

export default withFirebase(Index);